'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import ErrorPlaceholder from '@frontend/jetlend-web-ui/src/components/ErrorPlaceholder/ErrorPlaceholder';

const CONTAINER_STYLE: React.CSSProperties = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
};

export default function ErrorPage({
    error,
}: {
    error: Error & { digest?: string };
}) {
    // Sentry не логирует автоматически перехваченные ошибки, поэтому логируем их самостоятельно
    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <html lang="ru">
            <body>
                <div style={CONTAINER_STYLE}>
                    <ErrorPlaceholder startPageLocation="/" />
                </div>
            </body>
        </html>
    );
}